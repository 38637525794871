import React, { useCallback, useContext, useEffect, useState } from "react";
import api from "services/interceptor";
import { getSessionData, setSessionData } from "services/session.service";

const defaultUser = {
    id: "",
    mobileNumber: "",
    ise2code: "",
    packId: "",
    subscriptionId: "",
    name: "",
    image: "",
    totalPoints: 0,
    isPlaying: false,
    is_dummy: false,
    role: "user",
    createdAt: "",
    updatedAt: "",
    is_balance_expired: false,
};

const SessionContext = React.createContext({
    user: defaultUser,
    getUser: () => defaultUser,
    getAccessToken: () => "",
    updateToken: () => {},
    updateUser: () => {},
});

export const SessionProvider = ({ children }) => {
    const [user, setUser] = useState(defaultUser);
    const [token, setToken] = useState("");

    const getAccessToken = useCallback(() => {
        return getSessionData("token");
    }, []);

    const updateToken = useCallback((token) => {
        setSessionData("token", token);
        setToken(token);
    }, []);

    const updateUser = useCallback((inputUser) => {
        setUser((prevState) => ({ ...prevState, ...inputUser }));
    }, []);

    const getUser = useCallback(async () => {
        try {
            const url = "/auth/profile";
            const response = await api.get(url);

            if (response?.data?.data) {
                setUser(response?.data?.data);
            }
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        if (token) {
            getUser();
        } else {
            setToken(getAccessToken());
        }
    }, [getAccessToken, token, getUser]);

    return (
        <SessionContext.Provider
            value={{
                user,
                getUser,
                getAccessToken,
                updateToken,
                updateUser,
            }}
        >
            {children}
        </SessionContext.Provider>
    );
};

export function useSession() {
    const sessionContext = useContext(SessionContext);

    return sessionContext;
}
