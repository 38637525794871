import { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PolicyStep from "./PolicyStep";
import "./ConfirmSubscription.css";

const packFrequencyMap = new Map([
  ["daily", 107],
  ["weekly", 108],
]);

const ConfirmSubscription = ({
  formik,
  selectedPack,
  changeStep,
  language,
}) => {
  const { t } = useTranslation();
  const [showPolicy, setShowPolicy] = useState(false);

  function updateShowPolicy(shouldShow) {
    setShowPolicy(shouldShow);
  }

  function getConfirmationString(pack) {
    if (!pack) {
      return;
    }

    const amount = pack.pack_price;
    const frequency = t(
      packFrequencyMap.get(pack.pack_frequency)
    )?.toLowerCase();

    return [
      `${t("106")}`,
      `"Quiz Master ${Number.parseInt(amount)}ArTTC"`,
      `${t("102")} ${frequency} ${t("105")}`,
    ];
  }

  return !showPolicy ? (
    <>
      <div className="login-thumbnail2">
        <img src="/image/login_img.png" alt="login" />
      </div>

      <Form className="confirmation" onSubmit={formik.handleSubmit}>
        <h5 className="mx-auto my-3 text-center">
          {getConfirmationString(selectedPack).map((text) => (
            <p key={text} className="mx-auto my-0 text-center">
              {text}
            </p>
          ))}
        </h5>
        <Form.Check
          id="hasAcceptedTnc"
          name="hasAcceptedTnc"
          label={
            <p className="m-0">
              {t("109")}{" "}
              <a
                href="#1"
                className="fw-semibold"
                onClick={(e) => {
                  e.preventDefault();
                  updateShowPolicy(true);
                }}
              >
                {t("110")}
              </a>
            </p>
          }
          className="mx-auto"
          checked={formik.values.hasAcceptedTnc}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <div className="button-container">
          <button
            className="btn btn-primary d-block mx-auto fw-bold"
            type="button"
            onClick={() => changeStep(-1)}
          >
            {t("98")}
          </button>
          <button
            className="btn btn-primary d-block mx-auto fw-bold"
            type="submit"
            disabled={!formik.values.hasAcceptedTnc}
          >
            {t("78")}
          </button>
        </div>
      </Form>
    </>
  ) : (
    <PolicyStep language={language} updateShowPolicy={updateShowPolicy} />
  );
};

export default ConfirmSubscription;
