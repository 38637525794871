import React, { useEffect, useState } from "react";
import "./PolicyStep.css";

import policyEn from "locales/policies/policy.en.json";
import policyFr from "locales/policies/policy.fr.json";

const languageMap = new Map([
  ["0", policyEn],
  ["1", policyFr],
]);

const PolicyStep = ({ updateShowPolicy, language }) => {
  const [policy, setPolicy] = useState({});

  useEffect(() => {
    const mappedPolicy = languageMap.get(language);

    if (mappedPolicy) {
      setPolicy(mappedPolicy);
    } else {
      setPolicy(languageMap.get("1"));
    }
  }, [language]);

  return (
    <div className="home">
      <div className="main">
        <div className="container-fluid box-wrapper difficult">
          <div className="box-wrapper mx-auto text-center position-relative header-container">
            <span className="levels-title"></span>
            <span className="levels-title"></span>
            <button
              type="button"
              className="icon-button"
              onClick={() => updateShowPolicy(false)}
            >
              <img src="/assets/icons/close.svg" alt="close" />
            </button>
          </div>
          <div className="home-profile">
            <h4 className="text-center">{policy.heading}</h4>
            <div className="my-4">
              {policy?.subheadings?.map((section, i) => (
                <div key={section.id + i} className="mb-3">
                  <h6>
                    {section.id}. {section.title}
                  </h6>
                  {section.subheadings.map((subsection, j) => (
                    <p
                      key={subsection.id + j}
                      className="policy-description mb-0"
                    >
                      <span>{subsection.id ? `${subsection.id}. ` : ""}</span>
                      <span>{subsection.content}</span>
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyStep;
